<template>
    <div>
        <y-loading
            :active="$wait.is('pagination-loading')"
            height="50vh"
        >
            <div
                v-if="addresses && addresses.length"
                class="row"
            >
                <div class="col-12 col-lg-8 col-center">
                    <div class="table-wrapper">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th
                                        v-for="column in tableHeader"
                                        :key="column.id"
                                    >
                                        {{ column.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(row, index) in addresses"
                                    :key="row.id"
                                >
                                    <td class="invoice-block-value">
                                        {{ digitsHelper(rowNumber(index)) }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.title }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ pc(row) }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.address ? row.address : `-` }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.tel ? digitsHelper(row.tel) : `-` }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <y-pagination
                            v-if="paginationModel && paginationModel.last_page > 1"
                            :model="paginationModel"
                            :per-page="paginationModel ? paginationModel.per_page : 10"
                            @change="handlePageChange"
                        />
                    </div>
                </div>
            </div>

            <y-empty-state
                v-else
                class="mb0"
                :message="$t('person.profile.tabs.addresses.empty')"
            />
        </y-loading>
    </div>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import ProfileTabs from '@/modules/persons/mixins/ProfileTabs';

    export default {
        name: 'ProfileAddresses',

        mixins: [ProfileTabs],

        /**
         * @inheritDoc
         */
        data() {
            return {
                tableHeader: [
                    {
                        title: this.$t('fields.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('fields.title'),
                        id   : 'title',
                    },
                    {
                        title: `${this.$t('fields.province')} - ${this.$t('fields.city')}`,
                        id   : 'division',
                    },
                    {
                        title: this.$t('fields.address'),
                        id   : 'address',
                    },
                    {
                        title: this.$t('fields.tel'),
                        id   : 'tel',
                    },
                ],
                addresses      : null,
                paginationModel: {
                    current_page: 1,
                    last_page   : 1,
                    per_page    : 10,
                },
            };
        },

        watch: {
            content: {
                /**
                 * Adopt data
                 */
                handler() {
                    this.$set(this, 'addresses', this.content.results);
                    this.paginationModel = this.content.metadata || this.paginationModel;
                },
                deep: true,
            },
        },

        methods: {
            /**
             * Return the combination of Province and City
             * 
             * @param {object} location - Object of the location
             * @returns {string}
             */
            pc(location) {
                if (location.province_name || location.city_name) {
                    return `${location.province_name} - ${location.city_name}`;
                }
                return '-';
            },

            /**
             * Digit helper for using in inline condition
             * 
             * @param {number} value - Number
             */
            digitsHelper(value) {
                return digits(value, this.$i18n.locale);
            },

            /**
             * Handles the page change and fetches data for the new page.
             *
             * @param {number} newPage - The page number to fetch data for.
             */
            handlePageChange(newPage) {
                this.fetchTabContent(this.endpoint, newPage);
            },
        },
    };
</script>
