<template>
    <div>
        <y-loading
            :active="$wait.is('pagination-loading')"
            height="50vh"
        >
            <div
                v-if="transactions && transactions.length"
                class="row"
            >
                <div class="col-12 col-lg-8 col-center">
                    <div class="table-wrapper">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th
                                        v-for="column in tableHeader"
                                        :key="column.id"
                                    >
                                        {{ column.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="transactions && transactions.length">
                                <tr
                                    v-for="(row, index) in transactions"
                                    :key="row.id"
                                >
                                    <td class="invoice-block-value">
                                        {{ digitsHelper(rowNumber(index)) }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.claimed_at ? dateHelper(row.claimed_at) : `-` }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.path_title ? digitsHelper(row.path_title) : `-` }}
                                    </td>
                                    <td class="invoice-block-value">
                                        <y-form-amount
                                            class="mb0"
                                            :element="{ value: row.amount }"
                                        />
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.currency.title }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.gateway_title ? digitsHelper(row.gateway_title) : `-` }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.status_label }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.confirmed_at ? dateHelper(row.confirmed_at) : `-` }}
                                    </td>
                                    <td class="invoice-block-value">
                                        {{ row.tracking_number ? digitsHelper(row.tracking_number) : `-` }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <y-pagination
                            :model="paginationModel"
                            :per-page="paginationModel ? paginationModel.per_page : 10"
                            :method="fetchTabContent"
                            @change="handlePageChange"
                        />
                    </div>
                </div>
            </div>

            <y-empty-state
                v-else
                class="mb0"
                :message="$t('person.profile.tabs.transactions.empty')"
            />
        </y-loading>
    </div>
</template>

<script>
    import YFormAmount from '@deps/form/elements/Amount';
    import ProfileTabs from '@/modules/persons/mixins/ProfileTabs';

    export default {
        name: 'ProfileTransactions',

        components: {
            YFormAmount,
        },

        mixins: [ProfileTabs],

        /**
         * @inheritDoc
         */
        data() {
            return {
                tableHeader: [
                    {
                        title: this.$t('finance.transactions.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('finance.transactions.claimed_at'),
                        id   : 'claimed_at',
                    },
                    {
                        title: this.$t('finance.transactions.path'),
                        id   : 'path',
                    },
                    {
                        title: this.$t('finance.transactions.amount'),
                        id   : 'amount',
                    },
                    {
                        title: this.$t('finance.transactions.title'),
                        id   : 'title',
                    },
                    {
                        title: this.$t('finance.transactions.gateway'),
                        id   : 'gateway',
                    },
                    {
                        title: this.$t('finance.transactions.status'),
                        id   : 'status',
                    },
                    {
                        title: this.$t('finance.transactions.confirmed_at'),
                        id   : 'confirmed_at',
                    },
                    {
                        title: this.$t('finance.transactions.tracking_number'),
                        id   : 'tracking_number',
                    },
                ],
                transactions: null,
            };
        },

        watch: {
            content: {
                /**
                 * Adopt data
                 */
                handler() {
                    this.$set(this, 'transactions', this.content.results);
                },
                deep: true,
            },
        },
        methods: {
            /**
             * Handles the page change event from the pagination component.
             * Fetches content for the new page by calling `fetchTabContent` with the updated page number.
             *
             * @param {number} newPage - The page number to fetch content for.
             */
            handlePageChange(newPage) {
                this.fetchTabContent(this.endpoint, newPage);
            },
        },
    };
</script>
