<template>
    <div>
        <y-loading
            :active="$wait.is('pagination-loading')"
            height="50vh"
        >
            <div
                v-if="history && history.length"
                class="row"
            >
                <div class="col-12 col-lg-8 col-center">
                    <div class="table-wrapper">
                        <table class="table bordered mb0">
                            <thead>
                                <tr>
                                    <th>{{ $t('profile.history.date') }}</th>
                                    <th>{{ $t('profile.history.ip') }}</th>
                                    <th>{{ $t('profile.history.status') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="row in history"
                                    :key="row.id"
                                    :class="[row.passed ? 'green' : 'red']"
                                >
                                    <td class="ltr">
                                        {{ row.created_at | date('YYYY/MM/DD - HH:mm:ss', $i18n.locale) | digits }}
                                    </td>
                                    <td>{{ row.ip }}</td>
                                    <td>
                                        <div
                                            v-if="row.passed"
                                            class="label green"
                                        >
                                            {{
                                                $t('profile.history.successful') }}
                                        </div>
                                        <div
                                            v-else
                                            class="label red"
                                        >
                                            {{ $t('profile.history.unsuccessful') }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <y-pagination
                            :model="paginationModel"
                            :per-page="paginationModel ? paginationModel.per_page : 10"
                            :method="fetchTabContent"
                            @change="handlePageChange"
                        />
                    </div>
                </div>
            </div>

            <y-empty-state
                v-else
                class="mb0"
                :message="$t('person.profile.tabs.login_history.empty')"
            />
        </y-loading>
    </div>
</template>

<script>
    import ProfileTabs from '@/modules/persons/mixins/ProfileTabs';

    export default {
        name: 'ProfileLoginHistory',

        mixins: [ProfileTabs],

        /**
         * @inheritdoc
         */
        data() {
            return {
                history: null,
            };
        },

        watch: {
            content: {
                /**
                 * Adopt data
                 */
                handler() {
                    this.$set(this, 'history', this.content.results);
                },
                deep: true,
            },
        },
        methods: {
            /**
             * Handles the page change event from the pagination component.
             * Fetches content for the new page by calling `fetchTabContent` with the updated page number.
             *
             * @param {number} newPage - The page number to fetch content for.
             */
            handlePageChange(newPage) {
                this.fetchTabContent(this.endpoint, newPage);
            },
        },

    };
</script>
